<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :disabled="readonly"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        hide-details
        :label="label"
        readonly
        :value="formatted"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      locale="de"
      :first-day-of-week="1"
      :value="value"
      @input="input"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  name: "DateInput",
  props: {
    label: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    value: String,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    formatted() {
      return moment(this.value).format('L');
    },
  },
  methods: {
    input(event) {
      this.$emit("input", event);
      this.menu = false;
    },
  },
};
</script>

<style scoped>
.v-input {
  margin: 0;
}
.v-text-field >>> input {
  cursor: pointer;
}
</style>
